<template>
    <div class="profile">
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t("Profile.Heading") }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t("Profile.PersonalProfile") }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t("Profile.Dashboard") }}</li>
                </ol>
            </div>
        </div>
        <div class="row" id="user-profile">
            <div class="col-lg-12">
                <div class="card">
                    <div class="border-top">
                        <div class="wideget-user-tab">
                            <div class="tab-menu-heading">
                                <div class="tabs-menu1">
                                    <ul class="nav">

                                        <li><a href="#editProfile" class="active show" data-bs-toggle="tab">{{ $t("Profile.PersonalProfile") }}</a></li>
                                        <li><a href="#email" data-bs-toggle="tab">{{ $t("Profile.ProfileEmail") }}</a></li>
                                        <li><a href="#phone" data-bs-toggle="tab">{{ $t("Profile.MobileNumber") }}</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content">

                    <div class="tab-pane active show" id="editProfile">
                        <div class="card">
                            <div class="card-body border-0">
                                <form class="form-horizontal">
                                    <div class="row p-5 mb-4">

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="username" class="form-label">{{ $t("Profile.FirstName") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.firstName.$model">
                                                <span v-if="$v.profileList.firstName.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.firstName.arabic">يرجى كتابه الاسم باللغة العربية<br /></span>
                                                    <span v-if="!$v.profileList.firstName.minLength">يرجى كتابه الاسم بالكامل</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="firstname" class="form-label">{{ $t("Profile.FatherName") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.middleName.$model">
                                                <span v-if="$v.profileList.middleName.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.middleName.arabic">يرجى كتابه اسم الاب باللغة العربية<br /></span>
                                                    <span v-if="!$v.profileList.middleName.minLength">يرجى كتابه اسم الاب بالكامل</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="lastname" class="form-label">{{ $t("Profile.FamilyName") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.lastName.$model">
                                                <span v-if="$v.profileList.lastName.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.lastName.arabic">
                                                        يرجى كتابه لقب العائلة باللغة العربية
                                                        <br />
                                                    </span>
                                                    <span v-if="!$v.profileList.lastName.minLength">يرجى كتابه لقب العائلة بالكامل</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="inputZip">{{ $t('Register.StudentCategory') }}<span class="error text-danger">*</span></label>
                                                <studentcategorydropdown v-model="profileList.studentCategoryId" :values="profileList.studentCategoryId" />
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t("Profile.UniverstyNumber") }}</label>
                                                <input type="text" class="form-control" id="designation" v-model="profileList.registrationCode">
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.IdentificationNumber") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.nationalId.$model" v-on:keyup="CheckNationalId(profileList.nationalId)">
                                                <span v-if="$v.profileList.nationalId.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.nationalId.number">يرجى كتابة المعرف بالأرقام</span>
                                                </span>
                                                <span v-if="isValidNationalId" class="text-danger">لا يمكن أن يكون أكثر أو أقل من 10 أرقام ويبدأ دائمًا بـ "1" أو "2"</span>

                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="username" class="form-label">{{ $t("Profile.MobileNumber") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.phoneNumber.$model" placeholder="05xxxxxxxx">
                                                <span v-if="$v.profileList.phoneNumber.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.phoneNumber.phoneNumber">الرجاء كتابة رقم هاتف محمول صحيح</span>
                                                    <span v-if="!$v.profileList.phoneNumber.minLength">الرجاء كتابة رقم هاتف محمول صحيح</span>

                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.Gender") }}</label>
                                                <genderdropdown v-model="profileList.gender" :values="profileList.gender" :key="genderRender" />
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <label>{{ $t('AdvisorRegistered.DateOfBirth') }}</label>
                                            <datepicker v-model="profileList.dateOfBirth" :key="dateOfBirthRender" />
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.Region") }}</label>
                                                <regionsdropdown v-model="profileList.regionId" :values="profileList.regionId" @input="UniversityCityRender()" :key="regionRender" />
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t("Profile.City") }}</label>
                                                <citydropdown v-model="profileList.cityId" :values="profileList.cityId" :regionid="profileList.regionId" :key="cityRender" />
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t("Profile.University") }}</label>
                                                <universitydropdown v-model="profileList.universityId" :values="profileList.universityId" @input="CollegeRender()" :regionid="profileList.regionId" :key="cityRender" />
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t('Register.College') }}</label>
                                                <college-dropdown v-model="profileList.collegeId" :universityid="profileList.universityId" :isall="false" :key="collegeRender" :values="profileList.collegeId" />
                                            </div>
                                        </div>

                                        <div v-if="role=='Adviser'" class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t("Profile.University") }}</label>
                                                <multiuniverstydropdown v-model="universityIds" :values="profileList.universityId" :allUniversity="true" :key="regionRender" />
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">التخصص الجامعي</label>
                                                <universtyspecilizationdropdown v-model="profileList.specializationId" :values="profileList.specializationId" />
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.Undergraduatelevel") }}</label>
                                                <universtyleveldropdown v-model="profileList.universityLevelId" :values="profileList.universityLevelId" :key="dateOfBirthRender" />
                                            </div>
                                        </div>



                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="inputZip">{{ $t('Register.Qualification') }}<span class="error text-danger">*</span></label>
                                                <qualificationdropdown v-model="profileList.qualificationId" :values="profileList.qualificationId" />
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-8">
                                            <div class="form-group col-md-8">
                                                <div class="material-switch">
                                                    <input id="someSwitchOptionPrimary" name="someSwitchOption001" v-model="profileList.isActive" type="checkbox" checked />
                                                    <label for="someSwitchOptionPrimary" class="label-primary"></label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-8">
                                            <a href="javascript:void(0)" v-on:click="SaveUser()" class="btn btn-primary ">{{ $t("Profile.Save") }}</a>
                                            <a href="javascript:void(0)" class="btn btn-danger mx-3" v-on:click="close">{{ $t('AddSessionLcoation.Cancel') }}</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <attachment-list :id="profileList.id" />

                    </div>

                    <div class="tab-pane " id="email">
                        <div class="card ">
                            <div class="card-body border-0">
                                <form class="form-horizontal">
                                    <div class="row p-5 mb-4">
                                        <p class="mb-4 text-17">{{ $t("Profile.EmailManagement") }}</p>

                                        <div class="col-md-12 col-lg-6 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.ProfileEmail") }}</label>
                                                <input type="text" class="form-control" v-model="profileList.email">
                                            </div>
                                        </div>



                                        <div class="col-md-12 col-lg-12 col-xl-8">
                                            <!--<a v-if="isHidden" href="javascript:void(0)" v-on:click="ChangeEmail(profileList.id, profileList.email),isHidden = false" class="btn btn-primary ">تحقق</a>-->
                                            <a href="javascript:void(0)" v-on:click="EmailVerification(profileList.id, profileList.email), isHidden = false" class="btn btn-primary ">{{ $t("Profile.Save") }}</a>


                                            <a href="javascript:void(0)" class="btn btn-danger mx-3" v-on:click="close">{{ $t('AddSessionLcoation.Cancel') }}</a>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane " id="phone">
                        <div class="card ">
                            <div class="card-body border-0">
                                <form class="form-horizontal">
                                    <div class="row p-5 mb-4">
                                        <p class="mb-4 text-17">{{ $t("Profile.MobileNumber") }}</p>

                                        <div class="col-md-12 col-lg-6 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.MobileNumber") }}</label>
                                                <input type="text" class="form-control" v-model="profileList.phoneNumber">
                                            </div>
                                        </div>

                                        <div v-if="!isHidden" class="col-md-12 col-lg-6 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("SessionActive.Code") }}</label>
                                                <input type="text" class="form-control" v-model="code">
                                            </div>
                                        </div>


                                        <div class="col-md-12 col-lg-12 col-xl-8">
                                            <a v-if="isHidden" href="javascript:void(0)" v-on:click="ChangePhone(profileList.id, profileList.phoneNumber),isHidden = false" class="btn btn-primary ">تحقق</a>
                                            <a v-if="!isHidden" href="javascript:void(0)" v-on:click="PhoneVerification(profileList.id, profileList.phoneNumber), isHidden = false" class="btn btn-primary ">{{ $t("Profile.Save") }}</a>


                                            <a href="javascript:void(0)" class="btn btn-danger mx-3" v-on:click="close">{{ $t('AddSessionLcoation.Cancel') }}</a>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
    </div>
</template>

<script>
    import { required, maxLength, sameAs, minLength } from 'vuelidate/lib/validators';
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        name: 'Profile',
        components: {
            Loading
        },
        data: function () {
            return {
                isValidNationalId: true,
                isHidden: true,
                loading: false,
                fullName: '',
                universityIds: [],
                cityRender: 0,
                regionRender: 0,
                collegeRender: 0,
                dateOfBirthRender: 0,
                universitiesRender: 0,
                genderRender: 0,
                displayUserName: '',
                registrationDate: '',
                displayProfilePicture: '',
                role: '',
                code: '',
                profileList: {
                    id: '',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    roleName: 'Student',
                    registrationCode: '',
                    nationalId: '',
                    phoneNumber: '',
                    profilePicture: '',
                    dateOfBirth: '',
                    gender: 0,
                    email: '',
                    cityId: '',
                    universityId: '',
                    studentCategoryId: '',
                    qualificationId: '',
                    regionId: '',
                    specializationId: '',
                    universityLevelId: '',
                    password: '',
                    confirmPassword: '',
                    oldPassword: '',
                    isActive: false,

                },
            }
        },
        validations: {
            profileList:
            {
                firstName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                middleName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                lastName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                nationalId:
                {
                    required,
                    number(id) {
                        return /^[0-9\u0660-\u0669]+$/.test(id)
                    }
                },
                phoneNumber:
                {
                    maxLength: maxLength(15),
                    minLength: minLength(10),
                    phonenumber(number) {
                        return  /^[0-9\u0660-\u0669]+$/.test(number)
                    }
                },
                password:
                {
                    required,
                    minLength: minLength(8),
                    strongPassword(password) {
                        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(password);
                    }
                },
                confirmPassword:
                {
                    required,
                    sameAsPassword: sameAs('password')
                },
            },

        },
        watch: {

        },
        methods: {
            CheckNationalId: function (no) {

                if (no.length != 10) {
                    this.isValidNationalId = true;
                }
                else {
                    var letter = no.substring(0, 1)
                    if (letter != '1' && letter != '2' && letter != '١' && letter != '٢') {
                        this.isValidNationalId = true;
                    }
                    else {
                        this.isValidNationalId = false;
                    }
                }

            },

            CollegeRender: function () {
                this.collegeRender++;
                this.profileList.collegeId = '';
            },

            close: function () {
                if (this.role == 'Adviser') { this.$router.push('/AdviserStudentList') }
                else {
                    this.$router.push('/StudentList')
                }

            },
            getPicture: function (path) {
                this.profileList.profilePicture = path;
                localStorage.setItem('ProfilePicture', path)
            },

            UniversityCityRender: function () {
                this.cityRender++;
                this.profileList.cityId = '';
                this.profileList.universityId = '';
                this.universityIds = [];
            },

            getLevelId: function (value) {
                var levelId = [];
                for (var i = 0; i < value.length; i++) {
                    levelId[i] = value[i].id
                }
                return levelId;
            },



            SaveUser: function () {
                
                var root = this;
                this.loading = true;

                this.fullName = this.profileList.firstName + this.profileList.middleName + this.profileList.lastName;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var url = '/account/StudentRegister';

                root.$https.post(url, this.profileList, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {

                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: 'User has been updated successfully.',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            localStorage.setItem('FullName', root.fullName)
                            root.loading = false;
                            root.close();
                        }
                        else {

                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {

                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },


            ChangeEmail: function (id, email) {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Home/VerficationEmail?studentId=' + id + '&email=' + email, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.loading = false;
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            EmailVerification: function (id, email) {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Home/SaveVerficationEmail?studentId = ' + id + '&email=' + email + "&code=" + this.code, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close();
                                    root.loading = false;

                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            ChangePhone: function (id, phone) {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('/Home/VerficationPhone?studentId=' + id + '&phone=' + phone, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.loading = false;
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            PhoneVerification: function (id, phone) {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('/Home/SaveVerficationPhone?studentId=' + id + '&phone=' + phone + "&code=" + this.code, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                      
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close();
                                    root.loading = false;

                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },
        },

        created: function () {
            
            if (this.$route.query.data != undefined) {
                this.profileList = this.$route.query.data;
                this.CheckNationalId(this.profileList.nationalId)
            }
            this.role = localStorage.getItem('RoleName');
        },
        mounted: function () {

        }
    }
</script>
<style scoped>
    .custom-file-label {
        display: none;
    }

    #profilePicture {
        max-height: 96px;
        max-width: 96px;
    }
</style>


